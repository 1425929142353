var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto ml-2 mr-2",attrs:{"type":"card","loading":_vm.loading}},[_c('CommunityInfo',{attrs:{"communityInfo":_vm.communityInfo},on:{"onRelatedCommunityChanged":_vm.showRelatedCommunity}}),_c('InternalBreadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('span',[_vm._v("Messages")]),_c('router-link',{staticClass:"link",attrs:{"to":("/customerView/" + _vm.customerId + "/messages/new?recipient=" + _vm.customerId)}},[_c('v-btn',{staticClass:"ml-5 white--text",attrs:{"small":""}},[_vm._v("New Message")])],1)],1),_c('v-card-text',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.records,"item-key":"id","loading":_vm.isLoading,"server-items-length":_vm.totalRecords,"options":_vm.options,"loading-text":"Loading... Please wait"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.direction",fn:function(ref){
var header = ref.header;
return [_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v(" "+_vm._s(_vm.mdiEmailReceive)+" ")]),_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',{attrs:{"color":"black","small":""}},[_vm._v(" "+_vm._s(_vm.mdiEmailSend)+" ")])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateUs(item.created))+" ")]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":("/customerView/" + _vm.customerId + "/messages/" + (item.id) + "/view")}},[_c('span',{style:(item.isNew ? 'color: green; font-weight: bold;' : '')},[_vm._v(" "+_vm._s(item.subject)+" ")])])]}},{key:"item.fromOrTo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fromOrTo)+" ")]}},{key:"item.direction",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":item.direction === 'In' ? 'green' : 'black'}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.direction === "In" ? _vm.mdiEmailReceive : _vm.mdiEmailSend)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.direction === "In" ? "Received" : "Sent"))])])]}},{key:"item.isActionRequired",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.isActionRequired ? "Yes" : "No"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"text":"","icon":""},on:{"click":function($event){$event.stopPropagation();item.dialog = true}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(item.dialog),callback:function ($$v) {_vm.$set(item, "dialog", $$v)},expression:"item.dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Delete Message ")]),_c('v-card-text',[_vm._v("Are you sure you want to delete message: "),_c('p',[_vm._v(_vm._s(item.subject))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){item.dialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.deleteMessage(item)}}},[_vm._v(" Delete ")])],1)],1)],1)]}}],null,true)})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }