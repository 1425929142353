<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0">
        <v-card-title>
          <span>Messages</span>
          <router-link
            class="link"
            :to="
              `/customerView/${customerId}/messages/new?recipient=${customerId}`
            "
          >
            <v-btn small class="ml-5 white--text">New Message</v-btn>
          </router-link>
        </v-card-title>
        <v-card-text class="mt-3">
          <!-- Start Messages section -->
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                :items="records"
                item-key="id"
                :loading="isLoading"
                :server-items-length="totalRecords"
                :options.sync="options"
                loading-text="Loading... Please wait"
                class="elevation-1"
              >
                <template #[`header.direction`]="{ header }">
                  <v-icon color="green" small>
                    {{ mdiEmailReceive }}
                  </v-icon>
                  {{ header.text }}
                  <v-icon color="black" small>
                    {{ mdiEmailSend }}
                  </v-icon>
                </template>
                <template #[`item.created`]="{ item }">
                  {{ formatDateUs(item.created) }}
                </template>
                <template #[`item.subject`]="{ item }">
                  <router-link
                    :to="`/customerView/${customerId}/messages/${item.id}/view`"
                  >
                    <span
                      :style="
                        item.isNew ? 'color: green; font-weight: bold;' : ''
                      "
                    >
                      {{ item.subject }}
                    </span>
                  </router-link>
                </template>
                <template #[`item.fromOrTo`]="{ item }">
                  {{ item.fromOrTo }}
                </template>
                <template #[`item.direction`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        :color="item.direction === 'In' ? 'green' : 'black'"
                      >
                        {{
                          item.direction === "In"
                            ? mdiEmailReceive
                            : mdiEmailSend
                        }}
                      </v-icon>
                    </template>
                    <span>{{
                      item.direction === "In" ? "Received" : "Sent"
                    }}</span>
                  </v-tooltip>
                </template>
                <template #[`item.isActionRequired`]="{ item }">
                  <span>{{ item.isActionRequired ? "Yes" : "No" }}</span>
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-btn
                    class="ma-2"
                    text
                    icon
                    @click.stop="item.dialog = true"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>

                  <v-dialog v-model="item.dialog" persistent max-width="290">
                    <v-card>
                      <v-card-title class="headline">
                        Delete Message
                      </v-card-title>
                      <v-card-text
                        >Are you sure you want to delete message:
                        <p>{{ item.subject }}</p></v-card-text
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="green darken-1"
                          text
                          @click="item.dialog = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="green darken-1"
                          text
                          @click="deleteMessage(item)"
                        >
                          Delete
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <!-- End Messages section -->
        </v-card-text>
      </v-card>
      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/customerView/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import {
  GET_QUERY,
  DELETE,
  API_CUSTOMERS,
  API_MESSAGES
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
// import { required } from "vuelidate/lib/validators";
import { mdiEmailReceiveOutline, mdiEmailSendOutline } from "@mdi/js";
// import moment from "moment";
import Mgr from "@/core/services/security.service";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    mgr: new Mgr(),
    communityInfo: {},
    headers: [
      {
        text: "Date",
        align: "start",
        value: "created",
        class: "orange-bg"
      },
      { text: "In/Out", value: "direction", class: "orange-bg" },
      { text: "From / Sent To", value: "fromOrTo", class: "orange-bg" },
      { text: "Reference", value: "subject", class: "orange-bg" },
      { text: "Action Req.", value: "isActionRequired", class: "orange-bg" },
      { text: "Actions", value: "actions", class: "orange-bg", sortable: false }
    ],
    records: [],
    totalRecords: null,
    dialog: false,
    mdiEmailReceive: mdiEmailReceiveOutline,
    mdiEmailSend: mdiEmailSendOutline,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["date"],
      sortDesc: [true]
    },
    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: []
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {
    options: async function() {
      await this.getMessages();
    }
  },
  async mounted() {
    // this.loading = true;
    // await this.getComunityInfo();
    // await this.createBreadcrumb();
    // await this.setInternalBreadcrumbs();
    // await this.getDataFromApi();
    // this.loading = false;
    await this.getMessages();
  },
  async created() {
    await this.getComunityInfo();
  },
  methods: {
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerView",
        params: { customerId: communityId }
      });
    },
    deleteMessage(item) {
      let self = this;
      if (!item) return;

      this.$store
        .dispatch(DELETE, {
          listName: `${API_MESSAGES}`,
          id: item.id
        })
        .then(function() {
          item.dialog = false;

          self.getMessages();
        });
    },
    getDirection(item) {
      // let self = this;
      this.mgr.getUser().then(userInfo => {
        item.direction = item.sender.id === userInfo.profile.sub ? "In" : "Out";
        // item.userInfo = userInfo;
      });
    },
    async getComunityInfo() {
      this.loading = true;

      this.isLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },
    async getMessages() {
      this.isLoading = true;
      let self = this;
      this.mgr.getUser().then(userInfo => {
        // item.userInfo = userInfo;

        this.$store
          .dispatch(GET_QUERY, {
            listName: `${API_MESSAGES}`
          })
          .then(response => {
            if (response.status >= 200 || response.status <= 204) {
              const { sortBy, sortDesc, page, itemsPerPage } = self.options;
              let items = response.data.$values;

              items.forEach(item => {
                if (item.sender.id === userInfo.profile.sub) {
                  item.direction = "Out";
                  item.fromOrTo = item.recipients.$values
                    .map(el => el.fullName)
                    .join(";");
                } else {
                  item.direction = "In";
                  item.fromOrTo = item.sender.fullName;
                }

                item.dialog = false;
              });

              const total = items.length;

              if (sortBy.length === 1 && sortDesc.length === 1) {
                items = items.sort((a, b) => {
                  const sortA = a[sortBy[0]];
                  const sortB = b[sortBy[0]];

                  if (sortDesc[0]) {
                    if (sortA < sortB) return 1;
                    if (sortA > sortB) return -1;
                    return 0;
                  } else {
                    if (sortA < sortB) return -1;
                    if (sortA > sortB) return 1;
                    return 0;
                  }
                });
              }

              if (itemsPerPage > 0) {
                items = items.slice(
                  (page - 1) * itemsPerPage,
                  page * itemsPerPage
                );
              }
              self.records = items;
              // self.records.forEach(element => {
              //   self.getDirection(element);
              // });
              self.totalRecords = total;
            } else {
              self.$snackbar.showMessage({
                content: response.data || "Something went wrong!",
                color: "red"
              });
            }
            self.isLoading = false;
          });
      });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        { title: "Messages" }
      ];
    },
    setStartingAfter() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.startingAfter = this.userInvoices[this.userInvoices.length - 1].id;
        this.endingBefore = "";
      }
    },
    setEndingBefore() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.endingBefore = this.userInvoices[0].id;
        this.startingAfter = "";
      }
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.communityInfo.name,
          route: "/customerView/" + this.customerId
        },
        { title: "Messages" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      // return moment(date).format("MM/DD/YYYY"); 
      return dateFormatter.formatDateUs(date);
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    }
  },
  computed: {
    totalItems() {
      if (
        (this.userInvoices &&
          this.userInvoices.length === this.options.itemsPerPage &&
          this.previousPage <= this.options.page &&
          this.hasMore) ||
        (this.options.page === 1 &&
          this.userInvoices.length === this.options.itemsPerPage)
      ) {
        return (this.options.page + 1) * this.options.itemsPerPage;
      }
      return this.options.page * this.options.itemsPerPage;
    },
    sort() {
      if (
        this.options.sortBy &&
        this.options.sortBy[0] &&
        this.options.sortBy[0].length > 0
      ) {
        if (this.options.sortBy[0] === "fullName") {
          return `firstName ${this.options.sortDesc[0] ? "desc" : "asc"}`;
        } else {
          return `${this.options.sortBy[0]} ${
            this.options.sortDesc[0] ? "desc" : "asc"
          }`;
        }
      }
      return "";
    }
  }
};
</script>
